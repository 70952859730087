import React, { Suspense } from 'react';

const LoadingSpinner = () => (
  <div className="fixed top-0 right-0 m-4 bg-white p-2 rounded-md shadow-md">
    <div className="flex items-center space-x-2">
      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-600"></div>
      <span className="text-sm text-gray-600">Chargement...</span>
    </div>
  </div>
);

export const I18nLoading = ({ children }) => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      {children}
    </Suspense>
  );
};

export default I18nLoading;