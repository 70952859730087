import React from 'react';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import ContactForm from 'components/ContactForm';
import { useTranslation } from 'react-i18next';

const ContactSection = () => {
  const { t } = useTranslation();

  const contactInfo = [
    {
      icon: FaEnvelope,
      label: t('contact.email.label'),
      value: 'contact@blueagate.io'
    },
    {
      icon: FaPhone,
      label: t('contact.phone.label'),
      value: '+41 79 647 63 91'
    },
    {
      icon: FaMapMarkerAlt,
      label: t('contact.address.label'),
      value: t('contact.address.value')
    }
  ];

  return (
    <section className="py-20 bg-blue-600">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">
            {t('contact.title')}
          </h2>
          <p className="text-xl text-blue-100 max-w-2xl mx-auto">
            {t('contact.subtitle')}
          </p>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
            <div className="bg-white rounded-lg shadow-lg p-8">
              <ContactForm />
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div className="bg-blue-700 rounded-lg shadow-lg p-8 h-full">
              <h3 className="text-2xl font-semibold text-white mb-6">
                {t('contact.information.title')}
              </h3>
              <div className="space-y-6">
                {contactInfo.map((info, index) => (
                  <div key={index} className="flex items-center">
                    <div className="flex-shrink-0 w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center">
                      <info.icon className="text-white text-xl" />
                    </div>
                    <div className="ml-4">
                      <h4 className="font-semibold text-blue-100">{info.label}</h4>
                      <p className="text-white">{info.value}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;