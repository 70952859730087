// src/i18n/translations/about.js

export const aboutUsTranslations = {
    fr: {
      about: {
        title: "À Propos de Blueagate",
        subtitle: "Votre Partenaire de Confiance en Cybersécurité",
        intro: {
          text: "Chez Blueagate, nous sommes passionnés par la protection des entreprises contre les menaces numériques croissantes. Fondée par une équipe d'experts en cybersécurité, notre mission est de fournir des solutions innovantes et personnalisées pour aider nos clients à naviguer en toute sécurité dans le paysage numérique moderne."
        },
        mission: {
          title: "Notre Mission",
          description: "Permettre aux entreprises de toutes tailles de protéger leurs actifs numériques, de maintenir la confiance de leurs clients et de prospérer dans un monde connecté."
        },
        whoWeAre: {
          title: "Qui Sommes-Nous ?",
          cards: {
            experts: {
              title: "Experts en Cybersécurité",
              description: "Une équipe composée de professionnels certifiés avec des années d'expérience dans le secteur."
            },
            innovators: {
              title: "Innovateurs Technologiques",
              description: "Nous utilisons les technologies les plus récentes pour anticiper et contrer les cybermenaces."
            },
            advisors: {
              title: "Conseillers de Confiance",
              description: "Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs défis uniques."
            },
            passionate: {
              title: "Passionnés par la Sécurité",
              description: "Notre engagement envers l'excellence nous pousse à constamment améliorer nos services."
            }
          }
        },
        values: {
          title: "Nos Valeurs",
          cards: {
            integrity: {
              title: "Intégrité",
              description: "Nous agissons avec honnêteté et transparence dans toutes nos interactions."
            },
            excellence: {
              title: "Excellence",
              description: "Nous nous efforçons d'offrir les normes les plus élevées en matière de service."
            },
            innovation: {
              title: "Innovation",
              description: "Nous encourageons la créativité et adoptons les nouvelles technologies."
            },
            commitment: {
              title: "Engagement Client",
              description: "La satisfaction de nos clients est au cœur de tout ce que nous faisons."
            }
          }
        },
        approach: {
          title: "Notre Approche",
          step_name: "Étape",
          steps: {
            analysis: {
              step: "1",
              title: "Analyse Personnalisée",
              description: "Évaluation approfondie de votre infrastructure et de vos risques spécifiques."
            },
            solutions: {
              step: "2",
              title: "Solutions Sur Mesure",
              description: "Élaboration de stratégies de sécurité adaptées à vos objectifs."
            },
            implementation: {
              step: "3",
              title: "Mise en Œuvre Efficace",
              description: "Déploiement rapide et efficace des solutions avec un minimum de perturbations."
            },
            support: {
              step: "4",
              title: "Support Continu",
              description: "Surveillance proactive et support 24/7 pour assurer une protection constante."
            }
          }
        },
        cta: {
          title: "Rejoignez-nous dans la Lutte contre les Cybermenaces",
          description: "La sécurité de votre entreprise est notre priorité absolue. Ensemble, nous pouvons construire un environnement numérique plus sûr pour vous et vos clients.",
          button: "Contactez-nous dès aujourd'hui"
        }
      }
    },
    en: {
      about: {
        title: "About Blueagate",
        subtitle: "Your Trusted Cybersecurity Partner",
        intro: {
          text: "At Blueagate, we are passionate about protecting businesses against growing digital threats. Founded by a team of cybersecurity experts, our mission is to provide innovative and customized solutions to help our clients navigate safely in the modern digital landscape."
        },
        mission: {
          title: "Our Mission",
          description: "To enable businesses of all sizes to protect their digital assets, maintain customer trust, and thrive in a connected world."
        },
        whoWeAre: {
          title: "Who We Are",
          cards: {
            experts: {
              title: "Cybersecurity Experts",
              description: "A team of certified professionals with years of industry experience."
            },
            innovators: {
              title: "Technology Innovators",
              description: "We use the latest technologies to anticipate and counter cyber threats."
            },
            advisors: {
              title: "Trusted Advisors",
              description: "We work closely with our clients to understand their unique challenges."
            },
            passionate: {
              title: "Passionate About Security",
              description: "Our commitment to excellence drives us to constantly improve our services."
            }
          }
        },
        values: {
          title: "Our Values",
          cards: {
            integrity: {
              title: "Integrity",
              description: "We act with honesty and transparency in all our interactions."
            },
            excellence: {
              title: "Excellence",
              description: "We strive to deliver the highest standards of service."
            },
            innovation: {
              title: "Innovation",
              description: "We encourage creativity and embrace new technologies."
            },
            commitment: {
              title: "Client Commitment",
              description: "Customer satisfaction is at the heart of everything we do."
            }
          }
        },
        approach: {
          title: "Our Approach",
          step_name: "Step",
          steps: {
            analysis: {
              step: "1",
              title: "Custom Analysis",
              description: "Thorough assessment of your infrastructure and specific risks."
            },
            solutions: {
              step: "2",
              title: "Tailored Solutions",
              description: "Development of security strategies adapted to your objectives."
            },
            implementation: {
              step: "3",
              title: "Efficient Implementation",
              description: "Quick and effective deployment of solutions with minimal disruption."
            },
            support: {
              step: "4",
              title: "Continuous Support",
              description: "Proactive monitoring and 24/7 support to ensure constant protection."
            }
          }
        },
        cta: {
          title: "Join Us in the Fight Against Cyber Threats",
          description: "Your business security is our top priority. Together, we can build a safer digital environment for you and your customers.",
          button: "Contact Us Today"
        }
      }
    }
  };