import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const footerLinks = {
    services: [
      { name: t('threatReduction.title'), path: '/services/reduction-menace' },
      { name: t('compliance.title'), path: '/services/compliance' },
      { name: t('incidentManagement.title'), path: '/services/gestion-incidents' },
      { name: t('footer.services.training'), path: '/services/formation' },
    ],
    resources: [
      { name: t('footer.resources.blog'), path: '/blog' },
      { name: t('footer.resources.whitepapers'), path: '/ressources/livres-blancs' },
      { name: t('footer.resources.webinars'), path: '/ressources/webinaires' },
      { name: t('footer.resources.faq'), path: '/faq' },
    ],
    company: [
      { name: t('navigation.about'), path: '/a-propos' },
      { name: t('footer.company.careers'), path: '/carrieres' },
      { name: t('footer.company.contact'), path: '/contact' },
      { name: t('footer.company.partners'), path: '/partenaires' },
    ],
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    
    // Mise à jour de l'URL avec la nouvelle langue
    const pathWithoutLang = location.pathname.replace(/^\/(fr|en)/, '');
    const newPath = `/${lng}${pathWithoutLang || ''}`;
    navigate(newPath);
  };

  // Fonction pour obtenir les chemins localisés
  const getLocalizedPath = (path) => {
    return `/${i18n.language}${path}`;
  };

  return (
    <footer className="bg-gray-900 text-white pt-16 pb-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.sections.services')}</h3>
            <ul className="space-y-2">
              {footerLinks.services.map((link) => (
                <li key={link.name}>
                  <Link to={getLocalizedPath(link.path)} className="text-gray-400 hover:text-white transition-colors">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.sections.resources')}</h3>
            <ul className="space-y-2">
              {footerLinks.resources.map((link) => (
                <li key={link.name}>
                  <Link to={getLocalizedPath(link.path)} className="text-gray-400 hover:text-white transition-colors">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.sections.company')}</h3>
            <ul className="space-y-2">
              {footerLinks.company.map((link) => (
                <li key={link.name}>
                  <Link to={getLocalizedPath(link.path)} className="text-gray-400 hover:text-white transition-colors">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.sections.follow_us')}</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
                <FaFacebookF size={20} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
                <FaTwitter size={20} />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
                <FaLinkedinIn size={20} />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">
                <FaYoutube size={20} />
              </a>
            </div>
            {/* Ajout du sélecteur de langue */}
            <div className="mt-6">
              <h4 className="text-sm font-semibold mb-2">{t('footer.language')}</h4>
              <div className="flex space-x-2">
                <button
                  onClick={() => changeLanguage('fr')}
                  className={`text-sm px-4 py-1 rounded-md transition-colors ${
                    i18n.language === 'fr' ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-800'
                  }`}
                >
                  FR
                </button>
                <button
                  onClick={() => changeLanguage('en')}
                  className={`text-sm px-4 py-1 rounded-md transition-colors ${
                    i18n.language === 'en' ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-800'
                  }`}
                >
                  EN
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-800 pt-8 mt-8 text-center text-gray-400">
          <p>{t('footer.copyright', { year: currentYear })}</p>
          <div className="mt-2 space-x-4">
            <Link to={getLocalizedPath('/mentions-legales')} className="hover:text-white transition-colors">
              {t('footer.legal')}
            </Link>
            <Link to={getLocalizedPath('/politique-de-confidentialite')} className="hover:text-white transition-colors">
              {t('footer.privacy')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;