// src/i18n/translations/legal.js

export const legalTranslations = {
    fr: {
      legal: {
        title: "Mentions Légales",
        introduction: "Les présentes mentions légales régissent l'utilisation du site internet {{company}}. En accédant à ce site, vous acceptez sans réserve les termes et conditions suivants.",
        
        sections: {
          editor: {
            title: "1. Éditeur du Site",
            content: "Le site {{website}} est édité par la société {{company}}, immatriculée au registre du commerce sous le numéro {{registrationNumber}}, dont le siège social est situé à {{city}}, Suisse."
          },
          
          hosting: {
            title: "2. Hébergement du Site",
            content: "Le site est hébergé par :",
            name: "Infomaniak",
            address: "Adresse : Rue Eugène Marziano 25, 1227 Les Acacias (GE)",
            phone: "Site web : www.infomaniak.com"
          },
          
          intellectual_property: {
            title: "3. Propriété Intellectuelle",
            content: "L'ensemble du contenu présent sur ce site, incluant, de manière non limitative, les textes, images, graphismes, logos, icônes et vidéos, est la propriété exclusive de {{company}} ou de ses partenaires. Toute reproduction, distribution, modification, adaptation ou diffusion des contenus sans autorisation préalable est interdite."
          },
          
          liability: {
            title: "4. Limitation de Responsabilité",
            content: "{{company}} s'efforce de fournir des informations exactes et à jour sur son site. Toutefois, la société ne peut être tenue responsable des erreurs ou omissions, ni des résultats qui pourraient être obtenus par l'usage de ces informations."
          },
          
          hyperlinks: {
            title: "5. Liens Hypertextes",
            content: "Le site {{website}} peut contenir des liens hypertextes vers d'autres sites web. {{company}} n'assume aucune responsabilité quant au contenu de ces sites tiers."
          },
          
          applicable_law: {
            title: "6. Droit Applicable",
            content: "Les présentes mentions légales sont régies par le droit suisse. Tout litige relatif à l'utilisation de ce site sera soumis à la juridiction exclusive des tribunaux compétents de {{city}}."
          },
          
          contact: {
            title: "7. Contact",
            content: "Pour toute question ou demande d'information concernant le site, vous pouvez nous contacter à l'adresse suivante :",
            email_label: "Email"
          }
        }
      }
    },
    
    en: {
      legal: {
        title: "Legal Notice",
        introduction: "These legal terms govern the use of the {{company}} website. By accessing this site, you unconditionally accept the following terms and conditions.",
        
        sections: {
          editor: {
            title: "1. Website Publisher",
            content: "The website {{website}} is published by {{company}}, registered under the commercial registry number {{registrationNumber}}, with headquarters located in {{city}}, Switzerland."
          },
          
          hosting: {
            title: "2. Website Hosting",
            content: "The site is hosted by:",
            name: "Host Name",
            address: "Address: [Host Address]",
            phone: "Phone: [Phone Number]"
          },
          
          intellectual_property: {
            title: "3. Intellectual Property",
            content: "All content present on this site, including but not limited to texts, images, graphics, logos, icons, and videos, is the exclusive property of {{company}} or its partners. Any reproduction, distribution, modification, adaptation, or dissemination of content without prior authorization is prohibited."
          },
          
          liability: {
            title: "4. Limitation of Liability",
            content: "{{company}} strives to provide accurate and up-to-date information on its website. However, the company cannot be held liable for any errors or omissions, nor for the results that may be obtained through the use of this information."
          },
          
          hyperlinks: {
            title: "5. Hyperlinks",
            content: "The {{website}} website may contain hyperlinks to other websites. {{company}} assumes no responsibility for the content of these third-party sites."
          },
          
          applicable_law: {
            title: "6. Applicable Law",
            content: "These legal terms are governed by Swiss law. Any dispute relating to the use of this site will be subject to the exclusive jurisdiction of the competent courts of {{city}}."
          },
          
          contact: {
            title: "7. Contact",
            content: "For any questions or information requests regarding the site, you can contact us at the following address:",
            email_label: "Email"
          }
        }
      }
    },
    
    ar: {
      legal: {
        title: "الإشعار القانوني",
        introduction: "تحكم هذه الشروط القانونية استخدام موقع {{company}}. بالوصول إلى هذا الموقع، فإنك تقبل دون تحفظ الشروط والأحكام التالية.",
        
        sections: {
          editor: {
            title: "١. ناشر الموقع",
            content: "يتم نشر موقع {{website}} من قبل شركة {{company}}، المسجلة تحت رقم السجل التجاري {{registrationNumber}}، ومقرها في {{city}}، سويسرا."
          },
          
          hosting: {
            title: "٢. استضافة الموقع",
            content: "يتم استضافة الموقع من قبل:",
            name: "اسم المضيف",
            address: "العنوان: [عنوان المضيف]",
            phone: "الهاتف: [رقم الهاتف]"
          },
          
          intellectual_property: {
            title: "٣. الملكية الفكرية",
            content: "جميع المحتويات الموجودة على هذا الموقع، بما في ذلك على سبيل المثال لا الحصر النصوص والصور والرسومات والشعارات والأيقونات والفيديوهات، هي ملكية حصرية لشركة {{company}} أو شركائها. يمنع أي نسخ أو توزيع أو تعديل أو تكييف أو نشر للمحتويات دون إذن مسبق."
          },
          
          liability: {
            title: "٤. حدود المسؤولية",
            content: "تسعى {{company}} لتقديم معلومات دقيقة وحديثة على موقعها. ومع ذلك، لا يمكن تحميل الشركة المسؤولية عن أي أخطاء أو إغفالات، أو عن النتائج التي قد يتم الحصول عليها من استخدام هذه المعلومات."
          },
          
          hyperlinks: {
            title: "٥. الروابط التشعبية",
            content: "قد يحتوي موقع {{website}} على روابط تشعبية لمواقع أخرى. لا تتحمل {{company}} أي مسؤولية عن محتوى هذه المواقع الخارجية."
          },
          
          applicable_law: {
            title: "٦. القانون المطبق",
            content: "تخضع هذه الشروط القانونية للقانون السويسري. يخضع أي نزاع يتعلق باستخدام هذا الموقع للاختصاص القضائي الحصري للمحاكم المختصة في {{city}}."
          },
          
          contact: {
            title: "٧. الاتصال",
            content: "لأي أسئلة أو طلبات معلومات بخصوص الموقع، يمكنك الاتصال بنا على العنوان التالي:",
            email_label: "البريد الإلكتروني"
          }
        }
      }
    }
  };