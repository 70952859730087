// src/i18n/translations/footer.js

export const footerTranslations = {
    fr: {
      footer: {
        sections: {
          services: "Services",
          resources: "Ressources",
          company: "Entreprise",
          follow_us: "Suivez-nous"
        },
        services: {
          cybersecurity: "Cybersécurité",
          security_audit: "Audit de sécurité",
          risk_management: "Gestion des risques",
          training: "Formation"
        },
        resources: {
          blog: "Blog",
          whitepapers: "Livres blancs",
          webinars: "Webinaires",
          faq: "FAQ"
        },
        company: {
          about: "À propos",
          careers: "Carrières",
          contact: "Contact",
          partners: "Partenaires"
        },
        social: {
          facebook: "Suivez-nous sur Facebook",
          twitter: "Suivez-nous sur Twitter",
          linkedin: "Suivez-nous sur LinkedIn",
          youtube: "Suivez-nous sur YouTube"
        },
        language: "Langue",
        copyright: "© {{year}} BlueAgate. Tous droits réservés.",
        legal: "Mentions légales",
        privacy: "Politique de confidentialité"
      }
    },
    
    en: {
      footer: {
        sections: {
          services: "Services",
          resources: "Resources",
          company: "Company",
          follow_us: "Follow Us"
        },
        services: {
          cybersecurity: "Cybersecurity",
          security_audit: "Security Audit",
          risk_management: "Risk Management",
          training: "Training"
        },
        resources: {
          blog: "Blog",
          whitepapers: "Whitepapers",
          webinars: "Webinars",
          faq: "FAQ"
        },
        company: {
          about: "About",
          careers: "Careers",
          contact: "Contact",
          partners: "Partners"
        },
        social: {
          facebook: "Follow us on Facebook",
          twitter: "Follow us on Twitter",
          linkedin: "Follow us on LinkedIn",
          youtube: "Follow us on YouTube"
        },
        language: "Language",
        copyright: "© {{year}} BlueAgate. All rights reserved.",
        legal: "Legal Notice",
        privacy: "Privacy Policy"
      }
    },
    
    ar: {
      footer: {
        sections: {
          services: "الخدمات",
          resources: "الموارد",
          company: "الشركة",
          follow_us: "تابعونا"
        },
        services: {
          cybersecurity: "الأمن السيبراني",
          security_audit: "تدقيق أمني",
          risk_management: "إدارة المخاطر",
          training: "التدريب"
        },
        resources: {
          blog: "المدونة",
          whitepapers: "الأوراق البيضاء",
          webinars: "الندوات عبر الإنترنت",
          faq: "الأسئلة الشائعة"
        },
        company: {
          about: "عن الشركة",
          careers: "الوظائف",
          contact: "اتصل بنا",
          partners: "الشركاء"
        },
        social: {
          facebook: "تابعنا على فيسبوك",
          twitter: "تابعنا على تويتر",
          linkedin: "تابعنا على لينكد إن",
          youtube: "تابعنا على يوتيوب"
        },
        language: "اللغة",
        copyright: "© {{year}} بلو أجيت. جميع الحقوق محفوظة.",
        legal: "الإشعار القانوني",
        privacy: "سياسة الخصوصية"
      }
    }
  };