import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const PolitiqueConfidentialite = () => {
  const { t } = useTranslation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    
  return (
    <div className="container mx-auto px-6 py-16">
      <h1 className="text-4xl font-thin mb-8 text-center text-gray-100">
        {t('privacy.title')}
      </h1>
      <div className="p-8 shadow-lg rounded-lg">
        <p className="text-lg text-gray-100 mb-6 leading-relaxed">
          {t('privacy.introduction', {
            company: <span className="font-bold text-blue-600">Blueagate</span>
          })}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('privacy.sections.data_collection.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('privacy.sections.data_collection.description')}
        </p>
        <ul className="list-inside list-disc space-y-2 text-gray-100">
          <li>{t('privacy.sections.data_collection.item1')}</li>
          <li>{t('privacy.sections.data_collection.item2')}</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('privacy.sections.data_usage.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('privacy.sections.data_usage.description')}
        </p>
        <ul className="list-inside list-disc space-y-2 text-gray-100">
          <li>{t('privacy.sections.data_usage.item1')}</li>
          <li>{t('privacy.sections.data_usage.item2')}</li>
          <li>{t('privacy.sections.data_usage.item3')}</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('privacy.sections.data_sharing.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('privacy.sections.data_sharing.description')}
        </p>
        <ul className="list-inside list-disc space-y-2 text-gray-100">
          <li>{t('privacy.sections.data_sharing.item1')}</li>
          <li>{t('privacy.sections.data_sharing.item2')}</li>
          <li>{t('privacy.sections.data_sharing.item3')}</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('privacy.sections.data_security.title')}
        </h2>
        <p className="mb-6 text-gray-100">
          {t('privacy.sections.data_security.content')}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('privacy.sections.your_rights.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('privacy.sections.your_rights.description')}
        </p>
        <ul className="list-inside list-disc space-y-2 text-gray-100">
          <li>{t('privacy.sections.your_rights.item1')}</li>
          <li>{t('privacy.sections.your_rights.item2')}</li>
          <li>{t('privacy.sections.your_rights.item3')}</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('privacy.sections.policy_updates.title')}
        </h2>
        <p className="mb-6 text-gray-100">
          {t('privacy.sections.policy_updates.content')}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('privacy.sections.contact.title')}
        </h2>
        <p className="text-gray-100">
          {t('privacy.sections.contact.content')}
        </p>
        <p className="text-gray-100 font-medium mt-4">
          <strong>{t('privacy.sections.contact.email_label')}:</strong>{' '}
          <a 
            href="mailto:contact@blueagate.io" 
            className="text-blue-600 hover:underline"
          >
            contact@blueagate.io
          </a>
        </p>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialite;