import React from 'react';
import { Link } from 'react-router-dom';
import { FaShieldAlt, FaUsers, FaChartLine } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const AboutSection = () => {
  const { t, i18n } = useTranslation();

  // Debug - vérifier si les traductions sont chargées
  console.log('Current translations:', i18n.store.data);

  const features = [
    {
      icon: FaShieldAlt,
      title: t('home.about.features.protection.title'),
      description: t('home.about.features.protection.description')
    },
    {
      icon: FaUsers,
      title: t('home.about.features.expertise.title'),
      description: t('home.about.features.expertise.description')
    },
    {
      icon: FaChartLine,
      title: t('home.about.features.solutions.title'),
      description: t('home.about.features.solutions.description')
    }
  ];

  const reasons = [
    t('home.about.reasons.experience'),
    t('home.about.reasons.customSolutions'),
    t('home.about.reasons.technology'),
    t('home.about.reasons.support')
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-white to-blue-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
            <h2 className="text-3xl font-bold mb-4 text-blue-900">
              {t('home.about.title', { company: 'BlueAgate' })}
            </h2>
            <p className="text-gray-700 mb-6">
              {t('home.about.description', { company: 'BlueAgate' })}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              {features.map((feature, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md text-center">
                  <feature.icon className="text-4xl text-blue-500 mx-auto mb-2" />
                  <h3 className="font-semibold text-lg mb-1">{feature.title}</h3>
                  <p className="text-sm text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
            <Link to="/a-propos" className="bg-blue-600 text-white py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300">
              {t('home.about.learnMoreButton')}
            </Link>
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div className="bg-white p-8 rounded-lg shadow-xl">
              <h3 className="text-2xl font-semibold mb-6 text-blue-900">
                {t('home.about.whyChooseUs.title')}
              </h3>
              <ul className="space-y-4">
                {reasons.map((reason, index) => (
                  <li key={index} className="flex items-center text-gray-700">
                    <span className="bg-blue-500 rounded-full p-1 mr-3">
                      <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                    </span>
                    {reason}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;