import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const LanguageRouter = ({ children }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  
  // Get base language code (fr, en) without region
  const getBaseLanguage = (locale) => locale.split('-')[0];
  
  const supportedLanguages = useMemo(() => ['fr', 'en'], []);
  
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const languageFromPath = pathSegments[1];
    const currentBaseLanguage = getBaseLanguage(currentLanguage);
    
    // If we're at the root path, redirect to default language
    if (location.pathname === '/') {
      const defaultLanguage = supportedLanguages.includes(currentBaseLanguage) 
        ? currentBaseLanguage 
        : 'en';
      navigate(`/${defaultLanguage}`, { replace: true });
      return;
    }

    // Handle paths without language prefix
    if (!supportedLanguages.includes(languageFromPath)) {
      // Determine which language to use
      const targetLanguage = supportedLanguages.includes(currentBaseLanguage) 
        ? currentBaseLanguage 
        : 'en';
      
      // Only redirect if we're not already processing a redirect
      if (!location.pathname.startsWith(`/${targetLanguage}`)) {
        const newPath = `/${targetLanguage}${location.pathname}`;
        navigate(newPath, { replace: true });
      }
      return;
    }

    // Handle language mismatch
    if (languageFromPath !== currentBaseLanguage) {
      i18n.changeLanguage(languageFromPath);
    }
  }, [location.pathname, currentLanguage, i18n, navigate, supportedLanguages]);

  // Generate alternate links for SEO
  const alternateLinks = supportedLanguages.map(lang => {
    const pathWithoutLang = location.pathname.replace(/^\/(fr|en)/, '') || '/';
    return {
      hrefLang: lang,
      href: `${window.location.origin}/${lang}${pathWithoutLang === '/' ? '' : pathWithoutLang}`
    };
  });

  return (
    <>
      <Helmet>
        <html lang={currentLanguage} />
        <meta property="og:locale" content={currentLanguage} />
        
        {alternateLinks.map(({ hrefLang, href }) => (
          <link 
            key={hrefLang}
            rel="alternate" 
            hrefLang={hrefLang} 
            href={href}
          />
        ))}
      </Helmet>
      {children}
    </>
  );
};

export default LanguageRouter;