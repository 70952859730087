import React from 'react';
import ArticleList from '../components/ArticleList';
import ScheduleControl from '../components/ScheduleControl';

const AdminPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Administration du blog</h1>
      <ScheduleControl />
      <ArticleList />
    </div>
  );
};

export default AdminPage;