import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Hero = ({ scrollToContact }) => {
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => setOffset(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="hero relative h-screen flex items-center justify-center overflow-hidden">
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: "url('/images/cyber-background.jpg')",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          transform: `translateY(${offset * 0.5}px)`
        }}
      />
      <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
      <div className="container mx-auto relative z-20 text-center px-4">
        <h2 className="text-4xl md:text-6xl font-bold mb-6 text-white leading-tight">
          {t('hero.title.start')} <span className="text-blue-400">{t('hero.title.highlight')}</span>
        </h2>
        <p className="text-xl mb-10 text-gray-300 max-w-2xl mx-auto">
          {t('hero.description')}
        </p>
        <button 
          onClick={scrollToContact} 
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-full transition-colors duration-300 inline-block"
        >
          {t('hero.ctaButton')}
        </button>
      </div>
    </section>
  );
};

export default Hero;