import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Shield, 
  Users, 
  Brain,
  Star,
  CheckCircle,
  Lightbulb,
  Heart,
  Search,
  Settings,
  Clock,
  Award,
  TrendingUp
} from 'lucide-react';

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-blue-800">
        <div className="relative z-10 container mx-auto px-4 pt-24 pb-16">
          <div className="text-center mb-16">
            <h1 className="text-[2.75rem] leading-[3.25rem] font-light mb-6 text-white">
              {t('about.title')}
            </h1>
            <p className="text-[1.5rem] leading-[2rem] font-light text-blue-100 max-w-3xl mx-auto">
              {t('about.subtitle')}
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <svg className="w-full h-16 text-white" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 100 C 20 0 50 0 100 100 Z" fill="currentColor"/>
          </svg>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        {/* Introduction */}
        <div className="grid md:grid-cols-2 gap-8 items-center bg-gray-50 rounded-xl shadow-lg overflow-hidden mb-16">
          <div className="p-8">
            <p className="text-lg font-light text-gray-700 leading-relaxed">
              {t('about.intro.text')}
            </p>
          </div>
          <div className="h-full">
            <img 
              src="/images/blueagate2.jpg"
              alt="Blueagate Team" 
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Notre Mission */}
        <div className="mb-16 text-center">
          <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-8 text-gray-900">
            {t('about.mission.title')}
          </h2>
          <p className="text-xl font-light text-gray-700 max-w-3xl mx-auto">
            {t('about.mission.description')}
          </p>
        </div>

        {/* Qui Sommes-Nous */}
        <div className="mb-16">
          <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-8 text-gray-900 text-center">
            {t('about.whoWeAre.title')}
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            <FeatureCard
              icon={<Shield className="w-8 h-8 text-blue-600" />}
              title={t('about.whoWeAre.cards.experts.title')}
              description={t('about.whoWeAre.cards.experts.description')}
            />
            <FeatureCard
              icon={<Brain className="w-8 h-8 text-blue-600" />}
              title={t('about.whoWeAre.cards.innovators.title')}
              description={t('about.whoWeAre.cards.innovators.description')}
            />
            <FeatureCard
              icon={<Users className="w-8 h-8 text-blue-600" />}
              title={t('about.whoWeAre.cards.advisors.title')}
              description={t('about.whoWeAre.cards.advisors.description')}
            />
            <FeatureCard
              icon={<Star className="w-8 h-8 text-blue-600" />}
              title={t('about.whoWeAre.cards.passionate.title')}
              description={t('about.whoWeAre.cards.passionate.description')}
            />
          </div>
        </div>

{/* Nos Valeurs */}
<div className="relative mb-24 py-20 px-8 sm:px-16">
  <div className="absolute inset-0 z-0 bg-blue-50 rounded-3xl" />
  <div className="relative z-10">
    <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-12 text-gray-900 text-center">
      {t('about.values.title')}
    </h2>
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 px-4">
      <ValueCard
        icon={<CheckCircle className="w-10 h-10 text-blue-600" />}
        title={t('about.values.cards.integrity.title')}
        description={t('about.values.cards.integrity.description')}
      />
      <ValueCard
        icon={<Award className="w-10 h-10 text-blue-600" />}
        title={t('about.values.cards.excellence.title')}
        description={t('about.values.cards.excellence.description')}
      />
      <ValueCard
        icon={<Lightbulb className="w-10 h-10 text-blue-600" />}
        title={t('about.values.cards.innovation.title')}
        description={t('about.values.cards.innovation.description')}
      />
      <ValueCard
        icon={<Heart className="w-10 h-10 text-blue-600" />}
        title={t('about.values.cards.commitment.title')}
        description={t('about.values.cards.commitment.description')}
      />
    </div>
  </div>
</div>

        {/* Notre Approche */}
        <div className="mb-16">
          <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-8 text-gray-900 text-center">
            {t('about.approach.title')}
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            <ApproachCard
              icon={<Search className="w-8 h-8 text-blue-600" />}
              step_name={t('about.approach.step_name')}
              step={t('about.approach.steps.analysis.step')}
              title={t('about.approach.steps.analysis.title')}
              description={t('about.approach.steps.analysis.description')}
            />
            <ApproachCard
              icon={<Settings className="w-8 h-8 text-blue-600" />}
              step_name={t('about.approach.step_name')}
              step={t('about.approach.steps.solutions.step')}
              title={t('about.approach.steps.solutions.title')}
              description={t('about.approach.steps.solutions.description')}
            />
            <ApproachCard
              icon={<TrendingUp className="w-8 h-8 text-blue-600" />}
              step_name={t('about.approach.step_name')}
              step={t('about.approach.steps.implementation.step')}
              title={t('about.approach.steps.implementation.title')}
              description={t('about.approach.steps.implementation.description')}
            />
            <ApproachCard
              icon={<Clock className="w-8 h-8 text-blue-600" />}
              step_name={t('about.approach.step_name')}
              step={t('about.approach.steps.support.step')}
              title={t('about.approach.steps.support.title')}
              description={t('about.approach.steps.support.description')}
            />
          </div>
        </div>

        {/* Call to Action */}
        <div className="relative rounded-xl overflow-hidden">
          <div className="absolute inset-0 z-0 bg-blue-600" />
          <div className="relative z-10 text-center p-16">
            <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-6 text-white">
              {t('about.cta.title')}
            </h2>
            <p className="text-xl font-light text-blue-100 mb-8 max-w-3xl mx-auto">
              {t('about.cta.description')}
            </p>
            <button className="bg-white hover:bg-blue-50 text-blue-600 font-bold py-4 px-8 rounded-lg shadow-lg transition-all transform hover:scale-105">
              {t('about.cta.button')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-6">
    <div className="flex flex-col items-center text-center gap-4">
      <div className="flex-shrink-0">
        {icon}
      </div>
      <div>
        <h3 className="text-xl font-light text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600 font-light">{description}</p>
      </div>
    </div>
  </div>
);

const ValueCard = ({ icon, title, description }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-8">
    <div className="flex flex-col items-center gap-6">
      <div className="bg-blue-50 rounded-full p-6 flex items-center justify-center w-24 h-24">
        {icon}
      </div>
      <h3 className="text-lg font-light text-gray-900">{title}</h3>
      <p className="text-gray-600 font-light text-center">{description}</p>
    </div>
  </div>
);

const ApproachCard = ({ icon, step, step_name, title, description }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-6 text-center">
    <div className="flex flex-col items-center gap-4">
      <div className="bg-blue-50 rounded-full p-4">
        {icon}
      </div>
      <div className="text-blue-600 font-bold">{step_name} {step}</div>
      <h3 className="text-lg font-light text-gray-900">{title}</h3>
      <p className="text-gray-600 font-light">{description}</p>
    </div>
  </div>
);

export default About;