import React from 'react';

const Services = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-6">Nos Services</h1>
      <p>Contenu de la page "Nos services"...</p>
    </div>
  );
};

export default Services;