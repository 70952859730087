// src/i18n/translations/privacy.js

export const privacyTranslations = {
    fr: {
      privacy: {
        title: "Politique de Confidentialité",
        introduction: "Chez BlueAgate, nous attachons une grande importance à la confidentialité et à la sécurité des données de nos utilisateurs. Cette politique de confidentialité explique comment nous recueillons, utilisons, et protégeons vos informations personnelles lorsque vous utilisez nos services.",
        
        sections: {
          data_collection: {
            title: "1. Collecte des Informations",
            description: "Nous recueillons des informations personnelles lorsque vous interagissez avec nos services, y compris :",
            item1: "Les informations que vous fournissez directement (nom, adresse e-mail, etc.).",
            item2: "Les données collectées automatiquement via les cookies et autres technologies de suivi."
          },
          
          data_usage: {
            title: "2. Utilisation des Informations",
            description: "Les informations que nous collectons sont utilisées pour :",
            item1: "Fournir, maintenir et améliorer nos services de cybersécurité.",
            item2: "Assurer la sécurité et la protection de vos données.",
            item3: "Communiquer avec vous à propos de vos demandes et de nos services."
          },
          
          data_sharing: {
            title: "3. Partage des Informations",
            description: "Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :",
            item1: "Avec votre consentement explicite.",
            item2: "Pour répondre à des obligations légales ou réglementaires.",
            item3: "Pour protéger nos droits, la sécurité de nos services, ou la sécurité de nos utilisateurs."
          },
          
          data_security: {
            title: "4. Sécurité des Données",
            content: "Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos données contre les accès non autorisés, la divulgation, l'altération ou la destruction."
          },
          
          your_rights: {
            title: "5. Vos Droits",
            description: "Vous disposez de certains droits concernant vos données personnelles, notamment :",
            item1: "Le droit d'accéder à vos informations.",
            item2: "Le droit de rectification si vos données sont inexactes ou incomplètes.",
            item3: "Le droit de demander la suppression de vos données dans certaines circonstances."
          },
          
          policy_updates: {
            title: "6. Modifications de la Politique",
            content: "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page, et la date de mise à jour sera indiquée en haut de la politique."
          },
          
          contact: {
            title: "7. Contact",
            content: "Si vous avez des questions concernant cette politique de confidentialité ou sur la manière dont nous utilisons vos données, vous pouvez nous contacter à l'adresse suivante :",
            email_label: "Email"
          }
        }
      }
    },
    
    en: {
      privacy: {
        title: "Privacy Policy",
        introduction: "At {{company}}, we place great importance on the privacy and security of our users' data. This privacy policy explains how we collect, use, and protect your personal information when you use our services.",
        
        sections: {
          data_collection: {
            title: "1. Information Collection",
            description: "We collect personal information when you interact with our services, including:",
            item1: "Information you provide directly (name, email address, etc.).",
            item2: "Data collected automatically through cookies and other tracking technologies."
          },
          
          data_usage: {
            title: "2. Information Usage",
            description: "The information we collect is used to:",
            item1: "Provide, maintain, and improve our cybersecurity services.",
            item2: "Ensure the security and protection of your data.",
            item3: "Communicate with you about your requests and our services."
          },
          
          data_sharing: {
            title: "3. Information Sharing",
            description: "We do not share your personal information with third parties, except in the following cases:",
            item1: "With your explicit consent.",
            item2: "To comply with legal or regulatory obligations.",
            item3: "To protect our rights, the security of our services, or the security of our users."
          },
          
          data_security: {
            title: "4. Data Security",
            content: "We implement appropriate technical and organizational security measures to protect your data against unauthorized access, disclosure, alteration, or destruction."
          },
          
          your_rights: {
            title: "5. Your Rights",
            description: "You have certain rights regarding your personal data, including:",
            item1: "The right to access your information.",
            item2: "The right to rectification if your data is inaccurate or incomplete.",
            item3: "The right to request deletion of your data in certain circumstances."
          },
          
          policy_updates: {
            title: "6. Policy Updates",
            content: "We reserve the right to modify this privacy policy at any time. Any changes will be posted on this page, and the update date will be indicated at the top of the policy."
          },
          
          contact: {
            title: "7. Contact",
            content: "If you have any questions about this privacy policy or how we use your data, you can contact us at the following address:",
            email_label: "Email"
          }
        }
      }
    },
    
    ar: {
      privacy: {
        title: "سياسة الخصوصية",
        introduction: "في {{company}}، نولي أهمية كبيرة لخصوصية وأمن بيانات مستخدمينا. توضح سياسة الخصوصية هذه كيفية جمع واستخدام وحماية معلوماتك الشخصية عند استخدام خدماتنا.",
        
        sections: {
          data_collection: {
            title: "١. جمع المعلومات",
            description: "نقوم بجمع المعلومات الشخصية عند تفاعلك مع خدماتنا، بما في ذلك:",
            item1: "المعلومات التي تقدمها مباشرة (الاسم، البريد الإلكتروني، إلخ).",
            item2: "البيانات التي يتم جمعها تلقائيًا من خلال ملفات تعريف الارتباط وتقنيات التتبع الأخرى."
          },
          
          data_usage: {
            title: "٢. استخدام المعلومات",
            description: "يتم استخدام المعلومات التي نجمعها من أجل:",
            item1: "توفير وصيانة وتحسين خدمات الأمن السيبراني لدينا.",
            item2: "ضمان أمن وحماية بياناتك.",
            item3: "التواصل معك بشأن طلباتك وخدماتنا."
          },
          
          data_sharing: {
            title: "٣. مشاركة المعلومات",
            description: "لا نشارك معلوماتك الشخصية مع أطراف ثالثة، إلا في الحالات التالية:",
            item1: "بموافقتك الصريحة.",
            item2: "للامتثال للالتزامات القانونية أو التنظيمية.",
            item3: "لحماية حقوقنا وأمن خدماتنا أو أمن مستخدمينا."
          },
          
          data_security: {
            title: "٤. أمن البيانات",
            content: "نقوم بتنفيذ تدابير أمنية تقنية وتنظيمية مناسبة لحماية بياناتك من الوصول غير المصرح به والكشف والتغيير أو التدمير."
          },
          
          your_rights: {
            title: "٥. حقوقك",
            description: "لديك حقوق معينة فيما يتعلق ببياناتك الشخصية، بما في ذلك:",
            item1: "الحق في الوصول إلى معلوماتك.",
            item2: "الحق في التصحيح إذا كانت بياناتك غير دقيقة أو غير كاملة.",
            item3: "الحق في طلب حذف بياناتك في ظروف معينة."
          },
          
          policy_updates: {
            title: "٦. تحديثات السياسة",
            content: "نحتفظ بالحق في تعديل سياسة الخصوصية هذه في أي وقت. سيتم نشر أي تغييرات على هذه الصفحة، وسيتم الإشارة إلى تاريخ التحديث في أعلى السياسة."
          },
          
          contact: {
            title: "٧. الاتصال",
            content: "إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه أو كيفية استخدامنا لبياناتك، يمكنك الاتصال بنا على العنوان التالي:",
            email_label: "البريد الإلكتروني"
          }
        }
      }
    }
  };