import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [captchaResponse, setCaptchaResponse] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaResponse) {
      alert("Veuillez compléter le captcha");
      return;
    }
    setIsSubmitting(true);
    setSubmitStatus(null);

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((result) => {
        console.log(result.text);
        setSubmitStatus('success');
        form.current.reset();
        setCaptchaResponse(null);
      }, (error) => {
        console.log(error.text);
        setSubmitStatus('error');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaResponse(value);
  };

  return (
    <form ref={form} onSubmit={handleSubmit} className="space-y-6">
      <div className="relative">
        <input
          type="text"
          id="user_name"
          name="user_name"
          required
          className="peer w-full px-3 py-2 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600 transition-all duration-300"
          placeholder="Votre nom"
        />
        <label
          htmlFor="user_name"
          className="absolute left-3 -top-2.5 text-sm text-gray-600 transition-all duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-blue-600"
        >
          Nom
        </label>
      </div>
      <div className="relative">
        <input
          type="email"
          id="user_email"
          name="user_email"
          required
          className="peer w-full px-3 py-2 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600 transition-all duration-300"
          placeholder="Votre email"
        />
        <label
          htmlFor="user_email"
          className="absolute left-3 -top-2.5 text-sm text-gray-600 transition-all duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-blue-600"
        >
          Email
        </label>
      </div>
      <div className="relative">
        <textarea
          id="message"
          name="message"
          rows="4"
          required
          className="peer w-full px-3 py-2 border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-600 transition-all duration-300 resize-none"
          placeholder="Votre message"
        ></textarea>
        <label
          htmlFor="message"
          className="absolute left-3 -top-2.5 text-sm text-gray-600 transition-all duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-blue-600"
        >
          Message
        </label>
      </div>
      <div className="flex justify-center">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleCaptchaChange}
        />
      </div>
      <div>
        <button
          type="submit"
          disabled={isSubmitting || !captchaResponse}
          className="w-full py-3 px-4 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
        </button>
      </div>
      {submitStatus === 'success' && (
        <p className="text-green-600 text-center animate-fade-in-down">Message envoyé avec succès !</p>
      )}
      {submitStatus === 'error' && (
        <p className="text-red-600 text-center animate-fade-in-down">Échec de l'envoi du message. Veuillez réessayer.</p>
      )}
    </form>
  );
};

export default ContactForm;