// src/i18n/translations/incident-management.js

export const incidentManagementTranslations = {
    fr: {
      incidentManagement: {
        title: "Gestion des Incidents",
        subtitle: "Réagissez efficacement aux cyberattaques pour minimiser l'impact sur votre entreprise",
        intro: {
          text: "Les cyberattaques sont inévitables, mais leurs conséquences peuvent être contrôlées. Chez Blueagate, nous vous aidons à gérer les incidents de sécurité de manière proactive et réactive, afin de protéger vos actifs les plus précieux et de maintenir la confiance de vos clients."
        },
        quickResponse: {
          title: "Une Réponse Rapide et Efficace aux Incidents",
          cards: {
            detection: {
              title: "Détection Précoce des Menaces",
              description: "Utilisation de systèmes avancés pour identifier les attaques dès qu'elles surviennent."
            },
            response: {
              title: "Réponse Immédiate",
              description: "Mise en place de protocoles d'intervention rapides pour limiter les dégâts."
            },
            forensics: {
              title: "Analyse Forensique",
              description: "Investigation approfondie pour comprendre l'origine de l'incident et éviter sa récurrence."
            },
            recovery: {
              title: "Rétablissement des Systèmes",
              description: "Restauration des services affectés pour assurer la continuité de vos opérations."
            }
          }
        },
        preparation: {
          title: "Préparation et Planification en Amont",
          cards: {
            planning: {
              title: "Élaboration de Plans",
              description: "Création de procédures claires pour guider vos équipes en cas d'incident."
            },
            testing: {
              title: "Tests et Simulations",
              description: "Organisation d'exercices pour vérifier l'efficacité des plans et améliorer les points faibles."
            },
            training: {
              title: "Formation du Personnel",
              description: "Sensibilisation de vos employés aux bonnes pratiques et à la reconnaissance des signes d'attaque."
            },
            updating: {
              title: "Mise à Jour Régulière",
              description: "Adaptation continue des plans en fonction des nouvelles menaces et technologies."
            }
          }
        },
        support: {
          title: "Support Continu et Accompagnement Personnalisé",
          cards: {
            assistance: {
              text: "Assistance 24/7 : Notre équipe est disponible à tout moment pour intervenir en cas d'urgence."
            },
            expertise: {
              text: "Conseils d'Experts : Bénéficiez de l'expertise de professionnels certifiés en gestion des incidents."
            },
            communication: {
              text: "Communication Transparente : Information régulière sur l'évolution de la situation et des actions menées."
            },
            strategy: {
              text: "Recommandations Stratégiques : Conseils pour renforcer votre posture de sécurité."
            }
          }
        },
        cta: {
          title: "Ne Laissez pas un Incident Compromettre Votre Activité",
          description: "Face à une cyberattaque, votre capacité à réagir fait toute la différence. Avec Blueagate, assurez-vous d'avoir un partenaire de confiance pour gérer efficacement les incidents et protéger votre entreprise.",
          button: "Contactez-nous dès aujourd'hui"
        }
      }
    },
    en: {
      incidentManagement: {
        title: "Incident Management",
        subtitle: "Respond effectively to cyberattacks to minimize impact on your business",
        intro: {
          text: "Cyberattacks are inevitable, but their consequences can be controlled. At Blueagate, we help you manage security incidents proactively and reactively to protect your most valuable assets and maintain customer trust."
        },
        quickResponse: {
          title: "Quick and Effective Incident Response",
          cards: {
            detection: {
              title: "Early Threat Detection",
              description: "Use of advanced systems to identify attacks as soon as they occur."
            },
            response: {
              title: "Immediate Response",
              description: "Implementation of rapid intervention protocols to limit damage."
            },
            forensics: {
              title: "Forensic Analysis",
              description: "Thorough investigation to understand the incident's origin and prevent recurrence."
            },
            recovery: {
              title: "System Recovery",
              description: "Restoration of affected services to ensure business continuity."
            }
          }
        },
        preparation: {
          title: "Advance Preparation and Planning",
          cards: {
            planning: {
              title: "Plan Development",
              description: "Creation of clear procedures to guide your teams in case of an incident."
            },
            testing: {
              title: "Tests and Simulations",
              description: "Organization of exercises to verify plan effectiveness and improve weak points."
            },
            training: {
              title: "Staff Training",
              description: "Employee awareness of best practices and recognition of attack signs."
            },
            updating: {
              title: "Regular Updates",
              description: "Continuous adaptation of plans based on new threats and technologies."
            }
          }
        },
        support: {
          title: "Continuous Support and Personalized Assistance",
          cards: {
            assistance: {
              text: "24/7 Assistance: Our team is available at any time to respond to emergencies."
            },
            expertise: {
              text: "Expert Advice: Benefit from the expertise of certified incident management professionals."
            },
            communication: {
              text: "Transparent Communication: Regular updates on situation development and actions taken."
            },
            strategy: {
              text: "Strategic Recommendations: Advice to strengthen your security posture."
            }
          }
        },
        cta: {
          title: "Don't Let an Incident Compromise Your Business",
          description: "In the face of a cyberattack, your ability to react makes all the difference. With Blueagate, ensure you have a trusted partner to effectively manage incidents and protect your business.",
          button: "Contact Us Today"
        }
      }
    }
  };