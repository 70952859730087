// Dans votre fichier de traductions (i18n.js ou un fichier séparé)

export const menuTranslations = {
  fr: {
    navigation: {
      about: 'À propos',
      services: 'Services',
      contact: 'Contact'
    },
    
    compliance: {
      title: 'Compliance'
    },
    incidentManagement: {
      title: 'Gestion des incidents'
    },
    // threatReduction est déjà défini dans votre fichier existant
  },
  en: {
    navigation: {
      about: 'About',
      services: 'Services',
      contact: 'Contact'
    },
    
    compliance: {
      title: 'Compliance'
    },
    incidentManagement: {
      title: 'Incident Management'
    },
    // threatReduction est déjà défini dans votre fichier existant
  }
};