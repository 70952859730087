import React, { useEffect } from 'react';

const ConditionsUtilisation = () => {
    // Utilise useEffect pour faire défiler la page en haut quand elle est rendue
    useEffect(() => {
      window.scrollTo(0, 0); // Fait défiler la page en haut
    }, []);  
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-6">Qui sommes-nous</h1>
      <p>Contenu de la page "qui sommes-nous"...</p>
    </div>
  );
};

export default ConditionsUtilisation;