import React, { useState } from 'react';
import useApi from '../hooks/useApi';

const ScheduleControl = () => {
  const [interval, setInterval] = useState(24);
  const { loading, error, callApi } = useApi();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await callApi('/api/set-schedule', 'POST', { interval_hours: interval });
    if (response) {
      alert(response.message);
    }
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-2xl font-bold mb-4">Contrôle de la planification</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="interval">
            Intervalle de génération (heures)
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="interval"
            type="number"
            value={interval}
            onChange={(e) => setInterval(parseInt(e.target.value))}
            min="1"
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Définir la planification
          </button>
        </div>
      </form>
      {loading && <p>Chargement...</p>}
      {error && <p className="text-red-500">Erreur: {error}</p>}
    </div>
  );
};

export default ScheduleControl;