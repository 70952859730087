import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const MentionsLegales = () => {
  const { t } = useTranslation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    
  return (
    <div className="container mx-auto px-6 py-16">
      <h1 className="text-4xl font-thin mb-8 text-center text-grey-100">
        {t('legal.title')}
      </h1>
      <div className="p-8 shadow-lg rounded-lg">
        <p className="text-lg text-gray-100 mb-6 leading-relaxed">
          {t('legal.introduction', { company: 'Blueagate' })}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('legal.sections.editor.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('legal.sections.editor.content', { 
            website: 'blueagate.io',
            company: 'Blueagate',
            registrationNumber: 'CHE-473.512.232',
            city: 'Vallorbe'
          })}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('legal.sections.hosting.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('legal.sections.hosting.content')}<br />
          <span className="font-bold">{t('legal.sections.hosting.name')}</span><br />
          {t('legal.sections.hosting.address')}<br />
          {t('legal.sections.hosting.phone')}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('legal.sections.intellectual_property.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('legal.sections.intellectual_property.content', { company: 'Blueagate' })}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('legal.sections.liability.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('legal.sections.liability.content', { company: 'Blueagate' })}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('legal.sections.hyperlinks.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('legal.sections.hyperlinks.content', { 
            website: 'blueagate.io',
            company: 'Blueagate'
          })}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('legal.sections.applicable_law.title')}
        </h2>
        <p className="mb-4 text-gray-100">
          {t('legal.sections.applicable_law.content', { city: 'Vallorbe' })}
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4 text-gray-100 border-b-2 pb-2 border-blue-600">
          {t('legal.sections.contact.title')}
        </h2>
        <p className="text-gray-100">
          {t('legal.sections.contact.content')}
        </p>
        <p className="text-gray-100 font-medium mt-4">
          <strong>{t('legal.sections.contact.email_label')}:</strong>{' '}
          <a 
            href="mailto:contact@blueagate.ch" 
            className="text-gray-100 hover:underline"
          >
            contact@blueagate.io
          </a>
        </p>
      </div>
    </div>
  );
};

export default MentionsLegales;