import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaShieldAlt, FaBalanceScale, FaExclamationTriangle, FaSearchengin } from 'react-icons/fa';

const ServiceCard = ({ icon: Icon, title, description, link }) => {
  const { t, i18n } = useTranslation();
  
  // Fonction pour obtenir les chemins localisés
  const getLocalizedPath = (path) => {
    return `/${i18n.language}${path}`;
  };
  
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl">
      <div className="p-6">
        <Icon className="text-4xl text-blue-600 mb-4" />
        <h3 className="text-xl font-semibold mb-2 text-blue-900">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        <Link 
          to={getLocalizedPath(link)} 
          className="text-blue-600 font-medium hover:text-blue-800 transition duration-300"
        >
          {t('services.learnMore')}
          <span className="ml-2">&rarr;</span>
        </Link>
      </div>
    </div>
  );
};

const ServicesSection = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // Fonction pour obtenir les chemins localisés
  const getLocalizedPath = (path) => {
    return `/${i18n.language}${path}`;
  };

  const services = [
    {
      icon: FaShieldAlt,
      title: t('services.cards.threatReduction.title'),
      description: t('services.cards.threatReduction.description'),
      link: "/services/reduction-menace"
    },
    {
      icon: FaBalanceScale,
      title: t('services.cards.compliance.title'),
      description: t('services.cards.compliance.description'),
      link: "/services/compliance"
    },
    {
      icon: FaExclamationTriangle,
      title: t('services.cards.incidentManagement.title'),
      description: t('services.cards.incidentManagement.description'),
      link: "/services/gestion-incidents"
    },
    {
      icon: FaSearchengin,
      title: t('services.cards.audit.title'),
      description: t('services.cards.audit.description'),
      link: "/services/audit"
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-blue-900 mb-4">{t('services.title')}</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            {t('services.subtitle')}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
        <div className="text-center mt-12">
          <Link 
            to={getLocalizedPath('/services')}
            className="bg-blue-600 text-white py-3 px-8 rounded-full hover:bg-blue-700 transition duration-300 inline-flex items-center"
          >
            {t('services.viewAllButton')}
            <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;