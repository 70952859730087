// src/i18n/translations/contact.js

export const contactTranslations = {
    fr: {
      contact: {
        title: "Contactez-nous",
        subtitle: "Nous sommes là pour répondre à toutes vos questions sur la cybersécurité. N'hésitez pas à nous contacter.",
        information: {
          title: "Nos Coordonnées"
        },
        email: {
          label: "Email"
        },
        phone: {
          label: "Téléphone"
        },
        address: {
          label: "Adresse",
          value: "Chemin du contournement 14, 1337 Vallorbe, Suisse"
        }
      }
    },
    en: {
      contact: {
        title: "Contact Us",
        subtitle: "We're here to answer all your cybersecurity questions. Don't hesitate to get in touch.",
        information: {
          title: "Our Contact Information"
        },
        email: {
          label: "Email"
        },
        phone: {
          label: "Phone"
        },
        address: {
          label: "Address",
          value: "Chemin du contournement 14, 1337 Vallorbe, Switzerland"
        }
      }
    }
  };