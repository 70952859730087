import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useApi from '../hooks/useApi';

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const { loading, error, callApi } = useApi();


  useEffect(() => {
    const fetchArticles = async () => {
      const data = await callApi('/api/articles');
      if (data) {
        setArticles(data);
      }
    };
    fetchArticles(); // Initial fetch

    const intervalId = setInterval(fetchArticles, 60000); // Poll every minute

    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  const generateNewArticle = async () => {
    const newArticle = await callApi('/api/generate-article', 'POST');
    if (newArticle) {
      setArticles([newArticle, ...articles]);
    }
  };

  if (loading) return <p>Chargement des articles...</p>;
  if (error) return <p>Erreur: {error}</p>;

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl font-bold mb-4">Articles de blog</h1>
      <button
        onClick={generateNewArticle}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        Générer un nouvel article
      </button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {articles.map((article) => (
          <div key={article._id} className="bg-white shadow-md rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-2">{article.title}</h2>
            <p className="text-gray-600 mb-4">{article.content.substring(0, 150)}...</p>
            <Link
              to={`/article/${article._id}`}
              className="text-blue-500 hover:text-blue-700"
            >
              Lire la suite
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleList;

