import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from 'assets/logo.svg';

const Header = ({ scrollToContact }) => {
  const { t, i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const servicesTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Configuration du menu avec les traductions
  const menuItems = [
    { 
      name: t('navigation.services'), 
      path: '/services',
      subItems: [
        { name: t('threatReduction.title'), path: '/services/reduction-menace' },
        { name: t('compliance.title'), path: '/services/compliance' },
        { name: t('incidentManagement.title'), path: '/services/gestion-incidents' },
      ]
    },
    { name: t('navigation.about'), path: '/a-propos' }
  ];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    
    // Mise à jour de l'URL avec la nouvelle langue
    const pathWithoutLang = location.pathname.replace(/^\/(fr|en)/, '');
    const newPath = `/${lng}${pathWithoutLang || ''}`;
    navigate(newPath);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsServicesOpen(false);
  };

  const handleServicesMouseEnter = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const handleServicesMouseLeave = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 300);
  };

  const handleContactClick = () => {
    if (location.pathname === '/') {
      document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
    } else {
      const currentLang = i18n.language;
      navigate(`/${currentLang}`);
      setTimeout(() => {
        document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  };

  // Adapter les liens pour inclure la langue courante
  const getLocalizedPath = (path) => {
    return `/${i18n.language}${path}`;
  };

  return (
    <header className="fixed w-full z-50">
      <div className={`transition-all duration-300 ${isScrolled ? 'bg-blue-600 bg-opacity-90 shadow-lg' : 'bg-transparent'}`}>
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link to={getLocalizedPath('/')} className="text-2xl font-bold text-white hover:text-blue-300 transition-colors">
              <img src={logo} alt="BlueAgate Logo" className="h-10 w-auto" />
            </Link>
            
            <button 
              className="lg:hidden text-white focus:outline-none" 
              onClick={toggleMenu}
              aria-label="Toggle menu"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>

            <nav className="hidden lg:flex items-center space-x-6">
              <ul className="flex space-x-6 font-poppins text-sm tracking-wide">
                {menuItems.map((item) => (
                  <li key={item.name} className="relative">
                    {item.subItems ? (
                      <div
                        onMouseEnter={handleServicesMouseEnter}
                        onMouseLeave={handleServicesMouseLeave}
                        className="group"
                      >
                        <button
                          className="text-white hover:text-blue-300 transition-colors uppercase font-light focus:outline-none"
                        >
                          {item.name}
                        </button>
                        <div className={`absolute left-0 mt-2 w-64 rounded-md shadow-lg bg-blue-900 bg-opacity-95 ring-1 ring-black ring-opacity-5 transition-all duration-300 ${isServicesOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'}`}>
                          <div className="py-2">
                            {item.subItems.map((subItem) => (
                              <Link
                                key={subItem.name}
                                to={getLocalizedPath(subItem.path)}
                                className="block px-4 py-3 text-sm text-white hover:bg-blue-800 transition-colors duration-200"
                                onClick={() => setIsServicesOpen(false)}
                              >
                                {subItem.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Link 
                        to={getLocalizedPath(item.path)} 
                        className="text-white hover:text-blue-300 transition-colors uppercase font-light"
                      >
                        {item.name}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              <button
                onClick={handleContactClick}
                className="px-6 py-2 text-sm bg-blue-600 hover:bg-blue-700 text-white rounded-full transition-colors uppercase font-light"
              >
                {t('navigation.contact')}
              </button>
              {/* Sélecteur de langue */}
              <div className="flex space-x-2">
                <button
                  onClick={() => changeLanguage('fr')}
                  className={`text-sm px-2 py-1 rounded-md transition-colors ${
                    i18n.language === 'fr' ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-700'
                  }`}
                >
                  FR
                </button>
                <button
                  onClick={() => changeLanguage('en')}
                  className={`text-sm px-2 py-1 rounded-md transition-colors ${
                    i18n.language === 'en' ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-700'
                  }`}
                >
                  EN
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* Menu mobile inchangé mais avec les liens localisés */}
      <nav 
        className={`lg:hidden mt-4 overflow-hidden transition-all duration-300 ease-in-out ${
          isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <ul className="flex flex-col space-y-2 font-poppins text-sm tracking-wide bg-blue-900 bg-opacity-90 rounded-lg mx-4 p-4">
          {/* Sélecteur de langue mobile */}
          <li className="flex space-x-2 justify-center pb-2 border-b border-blue-800">
            <button
              onClick={() => changeLanguage('fr')}
              className={`text-sm px-4 py-1 rounded-md transition-colors ${
                i18n.language === 'fr' ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-700'
              }`}
            >
              FR
            </button>
            <button
              onClick={() => changeLanguage('en')}
              className={`text-sm px-4 py-1 rounded-md transition-colors ${
                i18n.language === 'en' ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-700'
              }`}
            >
              EN
            </button>
          </li>

          {menuItems.map((item) => (
            <li key={item.name}>
              {item.subItems ? (
                <>
                  <button
                    onClick={() => setIsServicesOpen(!isServicesOpen)}
                    className="w-full text-left block py-2 px-4 text-white hover:bg-blue-800 rounded transition-colors uppercase font-light"
                  >
                    {item.name}
                  </button>
                  <ul className={`ml-4 ${isServicesOpen ? 'block' : 'hidden'}`}>
                    {item.subItems.map((subItem) => (
                      <li key={subItem.name}>
                        <Link
                          to={getLocalizedPath(subItem.path)}
                          className="block py-2 px-4 text-white hover:bg-blue-800 rounded transition-colors"
                          onClick={() => {
                            setIsMenuOpen(false);
                            setIsServicesOpen(false);
                          }}
                        >
                          {subItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link 
                  to={getLocalizedPath(item.path)} 
                  className="block py-2 px-4 text-white hover:bg-blue-800 rounded transition-colors uppercase font-light"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.name}
                </Link>
              )}
            </li>
          ))}
          <li>
            <button
              onClick={() => {
                setIsMenuOpen(false);
                handleContactClick();
              }}
              className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded transition-colors uppercase font-light"
            >
              {t('navigation.contact')}
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;