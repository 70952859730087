// i18n/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { threatReductionTranslations } from 'i18n/translations/threatReduction';
import { menuTranslations } from 'i18n/translations/menuTranslations';
import { complianceTranslations } from 'i18n/translations/compliance';
import { heroTranslations } from 'i18n/translations/Hero';  // Ajout de l'import du Hero
import LanguageDetector from 'i18next-browser-languagedetector';
import { servicesTranslations } from './translations/services';
import { aboutTranslations } from './translations/about';
import { contactTranslations } from './translations/contact';
import { aboutUsTranslations } from './translations/AboutUsPage';
import { incidentManagementTranslations } from './translations/incident-management';
import { footerTranslations } from './translations/footer';
import { legalTranslations } from './translations/legal';
import { privacyTranslations } from './translations/privacy';

const resources = {
  fr: {
    translation: {
      // Navigation
      home: 'Accueil',
      whoWeAre: 'Qui sommes-nous',
      privacyPolicy: 'Politique de confidentialité',
      legalNotice: 'Mentions légales',
      termsOfUse: 'Conditions d\'utilisation',
      //services: 'Services',
      blog: 'Blog',
      about: 'À propos',
      
      // Services
      threatReduction: 'Réduction des menaces',
      compliance: 'Conformité',
      incidentManagement: 'Gestion des incidents',
      contact: 'Contact',

      // Importation des traductions
      ...menuTranslations.fr,
      ...threatReductionTranslations.fr,
      ...complianceTranslations.fr,
      ...heroTranslations.fr,  // Ajout des traductions du Hero
      ...servicesTranslations.fr,
      ...aboutTranslations.fr,
      ...contactTranslations.fr,
      ...aboutUsTranslations.fr,
      ...incidentManagementTranslations.fr,
      ...footerTranslations.fr,
      ...legalTranslations.fr,
      ...privacyTranslations.fr,
      
      // Routes et autres traductions existantes...
      routes: {
        // ... vos routes existantes
      }
    }
  },
  en: {
    translation: {
      // Navigation
      home: 'Home',
      whoWeAre: 'Who We Are',
      privacyPolicy: 'Privacy Policy',
      legalNotice: 'Legal Notice',
      termsOfUse: 'Terms of Use',
      services: 'Services',
      blog: 'Blog',
      about: 'About',
      
      // Services
      threatReduction: 'Threat Reduction',
      compliance: 'Compliance',
      incidentManagement: 'Incident Management',
      contact: 'Contact',
      
      // Importation des traductions
      ...menuTranslations.en,
      ...threatReductionTranslations.en,
      ...complianceTranslations.en,
      ...heroTranslations.en,  // Ajout des traductions du Hero
      ...servicesTranslations.en,
      ...aboutTranslations.en,
      ...contactTranslations.en,
      ...aboutUsTranslations.en,
      ...incidentManagementTranslations.en,
      ...footerTranslations.en,
      ...legalTranslations.en,
      ...privacyTranslations.en,
      
      // Routes et autres traductions existantes...
      routes: {
        // ... vos routes existantes
        whoWeAre: 'who-we-are',
        privacyPolicy: 'privacy-policy',
        legalNotice: 'legal-notice',
        termsOfUse: 'terms-of-use',
        services: 'services',
        blog: 'blog',
        about: 'about',
        threatReduction: 'services/threat-reduction',
        compliance: 'services/compliance',
        incidentManagement: 'services/incident-management',
        admin: 'admin'
      }
    }
  },
  ar: {
    translation: {
      // Navigation
      home: 'الرئيسية',
      whoWeAre: 'من نحن',
      privacyPolicy: 'سياسة الخصوصية',
      legalNotice: 'إشعار قانوني',
      termsOfUse: 'شروط الاستخدام',
      services: 'خدماتنا',
      blog: 'المدونة',
      about: 'حول',
      contact: 'اتصل بنا',
      
      // Importation des traductions arabes
      ...aboutTranslations.ar,
      // ... autres traductions ...
    }
  }  
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ['fr', 'en', 'ar'],
    fallbackLng: 'fr',
    defaultNS: 'translation',
    
    load: 'languageOnly',
    
    detection: {
      order: ['path', 'querystring', 'localStorage', 'navigator'],
      lookupFromPathIndex: 0,
      convertLanguageCodes: true, // Convertit fr-FR en fr
      checkWhitelist: true
    },

    interpolation: {
      escapeValue: false
    },

    appendNamespaceToCIMode: false
  });

export default i18n;