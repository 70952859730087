import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { Helmet } from 'react-helmet-async';

const MainLayout = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <meta httpEquiv="content-language" content={i18n.language} />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-br from-blue-900 via-blue-800 to-indigo-900 text-white">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;