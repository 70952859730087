// src/i18n/translations/about.js

export const aboutTranslations = {
  fr: {
    home: {
      about: {
        title: "À propos de {{company}}",
        description: "{{company}} est à l'avant-garde de la cybersécurité, offrant des solutions innovantes pour protéger votre entreprise dans un monde numérique en constante évolution. Notre équipe d'experts combine une connaissance approfondie du domaine avec les dernières technologies pour vous offrir une protection complète et sur mesure.",
        learnMoreButton: "En savoir plus",

        features: {
          protection: {
            title: "Protection 24/7",
            description: "Surveillance continue de votre infrastructure"
          },
          expertise: {
            title: "Expertise",
            description: "Une équipe de professionnels certifiés"
          },
          solutions: {
            title: "Solutions adaptées",
            description: "Stratégies personnalisées pour votre croissance"
          }
        },

        whyChooseUs: {
          title: "Pourquoi nous choisir ?",
        },

        reasons: {
          experience: "Plus de 10 ans d'expérience dans la cybersécurité",
          customSolutions: "Solutions sur mesure pour chaque client",
          technology: "Technologie de pointe et méthodologies éprouvées",
          support: "Support client réactif et dédié"
        }
      }
    }
  },
  en: {
    home: {
      about: {
        title: "About {{company}}",
        description: "{{company}} is at the forefront of cybersecurity, providing innovative solutions to protect your business in an ever-evolving digital world. Our team of experts combines deep domain knowledge with the latest technologies to offer you comprehensive and tailored protection.",
        learnMoreButton: "Learn more",

        features: {
          protection: {
            title: "24/7 Protection",
            description: "Continuous infrastructure monitoring"
          },
          expertise: {
            title: "Expertise",
            description: "A team of certified professionals"
          },
          solutions: {
            title: "Tailored Solutions",
            description: "Personalized strategies for your growth"
          }
        },

        whyChooseUs: {
          title: "Why Choose Us?",
        },

        reasons: {
          experience: "Over 10 years of cybersecurity experience",
          customSolutions: "Customized solutions for each client",
          technology: "Cutting-edge technology and proven methodologies",
          support: "Responsive and dedicated customer support"
        }
      }
    }
  },
  ar: {
    home: {
      about: {
        title: "حول {{company}}",
        description: "تقف {{company}} في طليعة الأمن السيبراني، حيث تقدم حلولاً مبتكرة لحماية شركتك في عالم رقمي دائم التطور. يجمع فريق خبرائنا بين المعرفة العميقة بالمجال وأحدث التقنيات لتوفير حماية شاملة ومخصصة لك.",
        learnMoreButton: "اعرف المزيد",

        features: {
          protection: {
            title: "حماية على مدار الساعة",
            description: "مراقبة مستمرة للبنية التحتية"
          },
          expertise: {
            title: "خبرة متخصصة",
            description: "فريق من المهنيين المعتمدين"
          },
          solutions: {
            title: "حلول مخصصة",
            description: "استراتيجيات مخصصة لنموك"
          }
        },

        whyChooseUs: {
          title: "لماذا تختارنا؟",
        },

        reasons: {
          experience: "أكثر من 10 سنوات من الخبرة في الأمن السيبراني",
          customSolutions: "حلول مخصصة لكل عميل",
          technology: "تكنولوجيا متطورة ومنهجيات مثبتة",
          support: "دعم عملاء سريع الاستجابة ومخصص"
        }
      }
    }
  }
};