// src/i18n/translations/threatReduction.js

export const threatReductionTranslations = {
    fr: {
      threatReduction: {
        title: "Réduction de la Menace",
        subtitle: "Sécurisez votre entreprise avec les solutions les plus avancées du marché",
        imageAlt: "Solutions de Cybersécurité",
        intro: "Dans un monde où les cybermenaces évoluent constamment, il est essentiel de protéger votre entreprise contre les attaques potentielles. Chez {{company}}, nous vous aidons à réduire votre exposition aux risques en utilisant des outils modernes et en vous offrant un suivi personnalisé.",
        modernTools: {
          title: "Réduisez votre Exposition grâce à des Outils Modernes",
          features: {
            vulnerability: {
              title: "Analyse Proactive des Vulnérabilités",
              description: "Nous effectuons des audits réguliers pour détecter les failles de sécurité dans vos systèmes et réseaux."
            },
            monitoring: {
              title: "Surveillance en Temps Réel",
              description: "Grâce à des outils avancés, nous surveillons continuellement votre infrastructure pour repérer les activités suspectes."
            },
            patches: {
              title: "Gestion Automatisée des Correctifs",
              description: "Nous assurons que tous vos logiciels et systèmes sont à jour avec les derniers correctifs de sécurité."
            },
            protection: {
              title: "Protection Contre les Menaces Avancées",
              description: "Nous déployons des solutions pour contrer les attaques sophistiquées telles que les ransomwares et les malwares zero-day."
            }
          }
        },
        personalizedSupport: {
          title: "Suivi Personnalisé pour une Sécurité Optimale",
          cards: {
            consultation: {
              title: "Consultation Dédiée",
              description: "Nos experts travaillent avec vous pour comprendre vos défis et élaborer une stratégie de sécurité adaptée."
            },
            training: {
              title: "Formation du Personnel",
              description: "Nous proposons des sessions de formation pour sensibiliser vos équipes aux bonnes pratiques de cybersécurité."
            },
            reports: {
              title: "Rapports Personnalisés",
              description: "Recevez des analyses détaillées sur l'état de votre sécurité et des recommandations pour l'améliorer."
            },
            support: {
              title: "Support Continu",
              description: "Notre équipe est disponible 24/7 pour vous assister en cas d'incident ou de question."
            }
          }
        },
        whyUs: {
          title: "Pourquoi Choisir Blueagate ?",
          cards: {
            expertise: "Expertise Avérée : Une équipe de professionnels certifiés et expérimentés en cybersécurité.",
            technology: "Technologies de Pointe : Utilisation des derniers outils et solutions pour une protection maximale.",
            approach: "Approche Personnalisée : Un service adapté à la taille et au secteur d'activité de votre entreprise.",
            commitment: "Engagement envers l'Excellence : Votre sécurité est notre priorité absolue."
          }
        },
        cta: {
          title: "Prenez les Devants contre les Cybermenaces",
          description: "Ne laissez pas les menaces numériques compromettre votre activité. Avec {{company}}, bénéficiez d'une protection complète et d'un partenariat dédié à votre réussite.",
          button: "Contactez-nous dès aujourd'hui"
        }
      }
    },
    en: {
      threatReduction: {
        title: "Threat Reduction",
        subtitle: "Secure your business with the most advanced solutions on the market",
        imageAlt: "Cybersecurity Solutions",
        intro: "In a world where cyber threats are constantly evolving, it's essential to protect your business against potential attacks. At {{company}}, we help you reduce your risk exposure using modern tools and providing personalized monitoring.",
        modernTools: {
          title: "Reduce Your Exposure with Modern Tools",
          features: {
            vulnerability: {
              title: "Proactive Vulnerability Analysis",
              description: "We perform regular audits to detect security flaws in your systems and networks."
            },
            monitoring: {
              title: "Real-Time Monitoring",
              description: "Using advanced tools, we continuously monitor your infrastructure to detect suspicious activities."
            },
            patches: {
              title: "Automated Patch Management",
              description: "We ensure all your software and systems are up to date with the latest security patches."
            },
            protection: {
              title: "Advanced Threat Protection",
              description: "We deploy solutions to counter sophisticated attacks such as ransomware and zero-day malware."
            }
          }
        },
        personalizedSupport: {
          title: "Personalized Support for Optimal Security",
          cards: {
            consultation: {
              title: "Dedicated Consultation",
              description: "Our experts work with you to understand your challenges and develop an adapted security strategy."
            },
            training: {
              title: "Staff Training",
              description: "We provide training sessions to raise awareness of cybersecurity best practices among your teams."
            },
            reports: {
              title: "Customized Reports",
              description: "Receive detailed analyses of your security status and recommendations for improvement."
            },
            support: {
              title: "Continuous Support",
              description: "Our team is available 24/7 to assist you with any incidents or questions."
            }
          }
        },
        whyUs: {
          title: "Why Choose Blueagate?",
          cards: {
            expertise: "Proven Expertise: A team of certified and experienced cybersecurity professionals.",
            technology: "Cutting-Edge Technology: Using the latest tools and solutions for maximum protection.",
            approach: "Personalized Approach: A service adapted to your company's size and industry sector.",
            commitment: "Commitment to Excellence: Your security is our absolute priority."
          }
        },
        cta: {
          title: "Stay Ahead of Cyber Threats",
          description: "Don't let digital threats compromise your business. With {{company}}, benefit from comprehensive protection and a partnership dedicated to your success.",
          button: "Contact Us Today"
        }
      }
    }
  };