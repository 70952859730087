import React, { useRef, useEffect } from 'react';
import Hero from 'components/Hero';
import AboutSection from 'components/AboutSection';
import ServicesSection from 'components/ServicesSection';
import ContactSection from 'components/ContactSection';
import { updateMetadata } from 'utils/seo';
import Header from 'components/Header';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const contactRef = useRef(null); // Référence pour la section contact
  const location = useLocation(); // Pour obtenir l'URL actuelle

  useEffect(() => {
    window.scrollTo(0, 0);
    // Mise à jour des métadonnées pour le SEO
    updateMetadata({
      title: "Blueagate - Votre partenaire en cybersécurité",
      description:
        "Blueagate offre des services de consulting en cybersécurité, incluant la réduction de la menace, la compliance, la gestion des incidents et l'audit.",
    });

    // Si l'utilisateur vient de la route /contact, scroller vers la section contact
    if (location.hash === '#contact-section' && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  // Fonction pour scroller vers la section contact
  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Passe la fonction scrollToContact au Header et au Hero */}
      <Header scrollToContact={scrollToContact} />
      <Hero scrollToContact={scrollToContact} />
      <ServicesSection />
      <AboutSection />


      {/* Ajoute la ref à la section Contact pour que le scroll fonctionne */}
      <div ref={contactRef} id="contact-section">
        <ContactSection />
      </div>
    </>
  );
};

export default Home;
