import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClipboardCheck,
  Scale,
  AlertCircle,
  Cpu,
  Settings2,
  BookOpen,
  Target,
  Lock,
  Eye,
  Shield,
  BarChart3,
  Award,
  Users,
  Handshake,
} from 'lucide-react';

const Compliance = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-blue-800">
        <div className="relative z-10 container mx-auto px-4 pt-24 pb-16">
          <div className="text-center mb-16">
            <h1 className="text-[2.75rem] leading-[3.25rem] font-light mb-6 text-white">
              {t('compliance.title')}
            </h1>
            <p className="text-[1.5rem] leading-[2rem] font-light text-blue-100 max-w-3xl mx-auto">
              {t('compliance.subtitle')}
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <svg className="w-full h-16 text-white" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 100 C 20 0 50 0 100 100 Z" fill="currentColor" />
          </svg>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        {/* Introduction */}
        <div className="grid md:grid-cols-2 gap-8 items-center bg-gray-50 rounded-xl shadow-lg overflow-hidden mb-16">
          <div className="p-8">
            <p className="text-lg font-light text-gray-700 leading-relaxed">
              {t('compliance.intro', { company: 'BlueAgate' })}
            </p>
          </div>
          <div className="h-full">
            <img
              src="/images/scott-graham-5fNmWej4tAA-unsplash.jpg"
              alt={t('compliance.imageAlt')}
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Navigation Section */}
        <div className="mb-16">
          <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-8 text-gray-900 text-center">
            {t('compliance.navigation.title')}
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <FeatureCard
              icon={<ClipboardCheck className="w-8 h-8 text-blue-600" />}
              title={t('compliance.navigation.features.audit.title')}
              description={t('compliance.navigation.features.audit.description')}
            />
            <FeatureCard
              icon={<Scale className="w-8 h-8 text-blue-600" />}
              title={t('compliance.navigation.features.conformity.title')}
              description={t('compliance.navigation.features.conformity.description')}
            />
            <FeatureCard
              icon={<Eye className="w-8 h-8 text-blue-600" />}
              title={t('compliance.navigation.features.monitoring.title')}
              description={t('compliance.navigation.features.monitoring.description')}
            />
            <FeatureCard
              icon={<AlertCircle className="w-8 h-8 text-blue-600" />}
              title={t('compliance.navigation.features.risk.title')}
              description={t('compliance.navigation.features.risk.description')}
            />
          </div>
        </div>

        {/* Outils Modernes Section */}
        <div className="relative mb-24 py-20 px-8 sm:px-16">
          <div className="absolute inset-0 z-0 bg-blue-50 rounded-3xl" />
          <div className="relative z-10">
            <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-12 text-gray-900 text-center">
              {t('compliance.tools.title')}
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 px-4">
              <SupportCard
                icon={<Shield className="w-8 h-8 text-blue-600" />}
                title={t('compliance.tools.cards.platform.title')}
                description={t('compliance.tools.cards.platform.description')}
              />
              <SupportCard
                icon={<Settings2 className="w-8 h-8 text-blue-600" />}
                title={t('compliance.tools.cards.automation.title')}
                description={t('compliance.tools.cards.automation.description')}
              />
              <SupportCard
                icon={<BarChart3 className="w-8 h-8 text-blue-600" />}
                title={t('compliance.tools.cards.analytics.title')}
                description={t('compliance.tools.cards.analytics.description')}
              />
              <SupportCard
                icon={<Award className="w-8 h-8 text-blue-600" />}
                title={t('compliance.tools.cards.reporting.title')}
                description={t('compliance.tools.cards.reporting.description')}
              />
            </div>
          </div>
        </div>
{/* Pourquoi Nous Choisir */}
<div className="mb-16 bg-gray-50 rounded-xl p-12 shadow-lg">
  <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-12 text-gray-900 text-center">
    {t('compliance.whyUs.title')}
  </h2>
  <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
    <WhyUsCard
      icon={<BookOpen className="w-8 h-8 text-blue-600" />}
      text={t('compliance.whyUs.cards.expertise')}
    />
    <WhyUsCard
      icon={<Cpu className="w-8 h-8 text-blue-600" />}
      text={t('compliance.whyUs.cards.technology')}
    />
    <WhyUsCard
      icon={<Handshake className="w-8 h-8 text-blue-600" />}
      text={t('compliance.whyUs.cards.approach')}
    />
    <WhyUsCard
      icon={<Users className="w-8 h-8 text-blue-600" />}
      text={t('compliance.whyUs.cards.commitment')}
    />
  </div>
</div>
        {/* Call to Action */}
        <div className="relative rounded-xl overflow-hidden">
          <div className="absolute inset-0 z-0 bg-blue-600" />
          <div className="relative z-10 text-center p-16">
            <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-6 text-white">
              {t('compliance.cta.title')}
            </h2>
            <p className="text-xl font-light text-blue-100 mb-8 max-w-3xl mx-auto">
              {t('compliance.cta.description', { company: 'Blueagate' })}
            </p>
            <button className="bg-white hover:bg-blue-50 text-blue-600 font-bold py-4 px-8 rounded-lg shadow-lg transition-all transform hover:scale-105">
              {t('compliance.cta.button')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Les composants restent les mêmes
const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-6">
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        {icon}
      </div>
      <div>
        <h3 className="text-xl font-light text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600 font-light">{description}</p>
      </div>
    </div>
  </div>
);

const SupportCard = ({ icon, title, description }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-6 text-center">
    <div className="flex flex-col items-center gap-4">
      <div className="bg-blue-50 rounded-full p-4">
        {icon}
      </div>
      <h3 className="text-lg font-light text-gray-900">{title}</h3>
      <p className="text-gray-600 font-light">{description}</p>
    </div>
  </div>
);

const WhyUsCard = ({ icon, text }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-8">
    <div className="flex items-start gap-6"> {/* Changed to items-start */}
      <div className="bg-blue-50 rounded-full p-4 flex-shrink-0 flex items-center justify-center w-20 h-20"> {/* Fixed dimensions and centering */}
        {icon}
      </div>
      <p className="text-gray-600 font-light text-lg pt-4"> {/* Added padding top for vertical alignment */}
        {text}
      </p>
    </div>
  </div>
);

export default Compliance;