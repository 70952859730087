import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import MainLayout from 'layouts/MainLayout';
import Home from 'pages/Home';
import QuiSommesNous from 'pages/QuiSommesNous';
import PolitiqueConfidentialite from 'pages/PolitiqueConfidentialite';
import MentionsLegales from 'pages/MentionsLegales';
import ConditionsUtilisation from 'pages/ConditionsUtilisation';
import Services from 'pages/Services';
import About from 'pages/Apropos';
import ReductionMenace from 'pages/services/ReductionMenace';
import Compliance from 'pages/services/Compliance';
import GestionIncidents from 'pages/services/GestionIncidents';
import AdminPage from 'pages/AdminPage';
import ArticleList from './components/ArticleList';
import ArticleDetail from './components/ArticleDetail';

// Import i18n
import I18nLoading from './components/I18nLoading';
import LanguageRouter from './components/LanguageRouter';
import './i18n/i18n';

// Composant pour gérer la langue HTML et la redirection
const LanguageManager = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    // Met à jour l'attribut lang du HTML
    document.documentElement.lang = i18n.language;

    // Gère la redirection si on est sur la route racine
    if (location.pathname === '/') {
      navigate(`/${i18n.language}`, { replace: true });
    }
  }, [navigate, i18n.language, location]);

  return null;
};

// Composant de redirection avec i18n
const RedirectWithLanguage = () => {
  const { i18n } = useTranslation();
  return <Navigate to={`/${i18n.language}`} replace />;
};

function App() {
  return (
    <HelmetProvider>
      <I18nLoading>
        <Router>
          <LanguageRouter>
            <LanguageManager />
            <MainLayout>
              <Routes>
                {/* Route par défaut - redirige vers la langue détectée */}
                <Route index element={<RedirectWithLanguage />} />

                {/* Routes avec préfixe de langue */}
                <Route path="/:lang">
                  {/* Pages principales */}
                  <Route index element={<Home />} />
                  <Route path="qui-sommes-nous" element={<QuiSommesNous />} />
                  <Route path="politique-de-confidentialite" element={<PolitiqueConfidentialite />} />
                  <Route path="mentions-legales" element={<MentionsLegales />} />
                  <Route path="conditions-utilisation" element={<ConditionsUtilisation />} />
                  <Route path="services" element={<Services />} />
                  <Route path="blog" element={<ArticleList />} />
                  <Route path="article/:id" element={<ArticleDetail />} />
                  <Route path="a-propos" element={<About />} />

                  {/* Services */}
                  <Route path="services">
                    <Route path="reduction-menace" element={<ReductionMenace />} />
                    <Route path="compliance" element={<Compliance />} />
                    <Route path="gestion-incidents" element={<GestionIncidents />} />
                  </Route>

                  {/* Admin */}
                  <Route path="adminpage" element={<AdminPage />} />
                </Route>

                {/* Redirection pour les URLs sans langue */}
                <Route path="*" element={<RedirectWithLanguage />} />
              </Routes>
            </MainLayout>
          </LanguageRouter>
        </Router>
      </I18nLoading>
    </HelmetProvider>
  );
}

export default App;