import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Shield, 
  Search, 
  Bell, 
  RefreshCw, 
  Users, 
  FileText, 
  HeadphonesIcon,
  Award,
  Cpu,
  Settings,
  Target,
  CheckCircle
} from 'lucide-react';

const ReductionMenace = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-blue-800">
        <div className="relative z-10 container mx-auto px-4 pt-24 pb-16">
          <div className="text-center mb-16">
            <h1 className="text-[2.75rem] leading-[3.25rem] font-light mb-6 text-white">
              {t('threatReduction.title')}
            </h1>
            <p className="text-2xl font-light text-blue-100 max-w-3xl mx-auto">
              {t('threatReduction.subtitle')}
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <svg className="w-full h-16 text-white" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 100 C 20 0 50 0 100 100 Z" fill="currentColor"/>
          </svg>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        {/* Introduction */}
        <div className="grid md:grid-cols-2 gap-8 items-center bg-gray-50 rounded-xl shadow-lg overflow-hidden mb-16">
          <div className="p-8">
            <p className="text-lg text-gray-700 leading-relaxed">
              {t('threatReduction.intro', { company: 'Blueagate' })}
            </p>
          </div>
          <div className="h-full">
            <img 
              src="/images/hack-capital-uv5_bsypFUM-unsplash.jpg"
              alt={t('threatReduction.imageAlt')} 
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Outils Modernes Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-gray-900 text-center">
            {t('threatReduction.modernTools.title')}
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <FeatureCard
              icon={<Search className="w-8 h-8 text-blue-600" />}
              title={t('threatReduction.modernTools.features.vulnerability.title')}
              description={t('threatReduction.modernTools.features.vulnerability.description')}
            />
            <FeatureCard
              icon={<Bell className="w-8 h-8 text-blue-600" />}
              title={t('threatReduction.modernTools.features.monitoring.title')}
              description={t('threatReduction.modernTools.features.monitoring.description')}
            />
            <FeatureCard
              icon={<RefreshCw className="w-8 h-8 text-blue-600" />}
              title={t('threatReduction.modernTools.features.patches.title')}
              description={t('threatReduction.modernTools.features.patches.description')}
            />
            <FeatureCard
              icon={<Shield className="w-8 h-8 text-blue-600" />}
              title={t('threatReduction.modernTools.features.protection.title')}
              description={t('threatReduction.modernTools.features.protection.description')}
            />
          </div>
        </div>

        <div className="relative mb-24 py-20 px-8 sm:px-16">
  <div className="absolute inset-0 z-0 bg-blue-50 rounded-3xl" />
  <div className="relative z-10">
    <h2 className="text-[2.25rem] leading-[2.75rem] font-light mb-12 text-gray-900 text-center">
      {t('threatReduction.personalizedSupport.title')}
    </h2>
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 px-4">
      <SupportCard
        icon={<Target className="w-8 h-8 text-blue-600" />}
        title={t('threatReduction.personalizedSupport.cards.consultation.title')}
        description={t('threatReduction.personalizedSupport.cards.consultation.description')}
      />
      <SupportCard
        icon={<Users className="w-8 h-8 text-blue-600" />}
        title={t('threatReduction.personalizedSupport.cards.training.title')}
        description={t('threatReduction.personalizedSupport.cards.training.description')}
      />
      <SupportCard
        icon={<FileText className="w-8 h-8 text-blue-600" />}
        title={t('threatReduction.personalizedSupport.cards.reports.title')}
        description={t('threatReduction.personalizedSupport.cards.reports.description')}
      />
      <SupportCard
        icon={<HeadphonesIcon className="w-8 h-8 text-blue-600" />}
        title={t('threatReduction.personalizedSupport.cards.support.title')}
        description={t('threatReduction.personalizedSupport.cards.support.description')}
      />
    </div>
  </div>
</div>

        {/* Pourquoi Nous Choisir */}
        <div className="mb-16 bg-gray-50 rounded-xl p-8 shadow-lg">
          <h2 className="text-3xl font-bold mb-8 text-gray-900 text-center">
            {t('threatReduction.whyUs.title')}
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            <WhyUsCard
              icon={<Award className="w-6 h-6 text-blue-600" />}
              text={t('threatReduction.whyUs.cards.expertise')}
            />
            <WhyUsCard
              icon={<Cpu className="w-6 h-6 text-blue-600" />}
              text={t('threatReduction.whyUs.cards.technology')}
            />
            <WhyUsCard
              icon={<Settings className="w-6 h-6 text-blue-600" />}
              text={t('threatReduction.whyUs.cards.approach')}
            />
            <WhyUsCard
              icon={<CheckCircle className="w-6 h-6 text-blue-600" />}
              text={t('threatReduction.whyUs.cards.commitment')}
            />
          </div>
        </div>

        {/* Call to Action */}
        <div className="relative rounded-xl overflow-hidden">
          <div className="absolute inset-0 z-0 bg-blue-600" />
          <div className="relative z-10 text-center p-16">
            <h2 className="text-3xl font-bold mb-6 text-white">
              {t('threatReduction.cta.title')}
            </h2>
            <p className="text-xl text-blue-100 mb-8 max-w-3xl mx-auto">
              {t('threatReduction.cta.description', { company: 'Blueagate' })}
            </p>
            <button className="bg-white hover:bg-blue-50 text-blue-600 font-bold py-4 px-8 rounded-lg shadow-lg transition-all transform hover:scale-105">
              {t('threatReduction.cta.button')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Les composants restent les mêmes
const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-6">
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">{icon}</div>
      <div>
        <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

const SupportCard = ({ icon, title, description }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-6 text-center">
    <div className="flex flex-col items-center gap-4">
      <div className="bg-blue-50 rounded-full p-4">{icon}</div>
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const WhyUsCard = ({ icon, text }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-all p-8">
    <div className="flex items-start gap-6"> {/* Changed to items-start */}
      <div className="bg-blue-50 rounded-full p-4 flex-shrink-0 flex items-center justify-center w-20 h-20"> {/* Fixed dimensions and centering */}
        {icon}
      </div>
      <p className="text-gray-600 font-light text-lg pt-4"> {/* Added padding top for vertical alignment */}
        {text}
      </p>
    </div>
  </div>
);

export default ReductionMenace;