// src/i18n/translations/compliance.js

export const complianceTranslations = {
    fr: {
      compliance: {
        title: "Compliance",
        subtitle: "Assurez la conformité de votre entreprise avec les réglementations en vigueur grâce à des solutions sur mesure",
        imageAlt: "Solutions de Compliance",
        intro: "Dans un environnement réglementaire de plus en plus complexe, il est essentiel pour les entreprises de se conformer aux lois et normes applicables. Chez {{company}}, nous vous aidons à naviguer dans ce paysage en constante évolution en vous offrant des solutions de compliance adaptées à vos besoins spécifiques.",
        
        navigation: {
          title: "Naviguez en Toute Confiance dans le Monde Réglementaire",
          features: {
            audit: {
              title: "Audit de Conformité",
              description: "Nous réalisons des audits approfondis pour identifier les écarts entre vos pratiques actuelles et les exigences réglementaires."
            },
            conformity: {
              title: "Mise en Conformité",
              description: "Nos experts vous accompagnent dans la mise en place de politiques et de procédures conformes aux normes nationales et internationales."
            },
            monitoring: {
              title: "Surveillance Réglementaire",
              description: "Nous vous tenons informé des évolutions législatives susceptibles d'impacter votre activité."
            },
            risk: {
              title: "Gestion des Risques",
              description: "Nous évaluons les risques de non-conformité et élaborons des plans d'action pour les atténuer."
            }
          }
        },
  
        tools: {
          title: "Des Outils Modernes pour une Conformité Efficace",
          cards: {
            platform: {
              title: "Plateformes de Gestion",
              description: "Centralisez toutes vos obligations réglementaires dans un seul outil pour un suivi simplifié."
            },
            automation: {
              title: "Automatisation des Processus",
              description: "Réduisez les erreurs humaines et gagnez du temps en automatisant les tâches répétitives."
            },
            analytics: {
              title: "Analytique Avancée",
              description: "Exploitez vos données pour anticiper les risques et prendre des décisions éclairées."
            },
            reporting: {
              title: "Reporting Personnalisé",
              description: "Recevez des rapports détaillés adaptés à vos besoins pour un pilotage efficace de la conformité."
            }
          }
        },
  
        whyUs: {
          title: "Pourquoi Choisir Blueagate pour Votre Compliance ?",
          cards: {
            expertise: "Expertise Multidisciplinaire : Une équipe composée de juristes, d'ingénieurs et de spécialistes en conformité.",
            technology: "Technologies Innovantes : Des outils modernes pour une gestion efficace et proactive de la conformité.",
            approach: "Approche Personnalisée : Des solutions sur mesure adaptées à votre secteur d'activité.",
            commitment: "Engagement et Discrétion : Une relation de confiance basée sur la confidentialité."
          }
        },
  
        cta: {
          title: "Prenez une Longueur d'Avance en Matière de Conformité",
          description: "Ne laissez pas la complexité réglementaire entraver votre succès. Avec {{company}}, transformez la compliance en un atout stratégique qui renforce votre réputation et votre performance.",
          button: "Contactez-nous dès aujourd'hui"
        }
      }
    },
    en: {
      compliance: {
        title: "Compliance",
        subtitle: "Ensure your company's compliance with current regulations through tailored solutions",
        imageAlt: "Compliance Solutions",
        intro: "In an increasingly complex regulatory environment, it is essential for companies to comply with applicable laws and standards. At {{company}}, we help you navigate this ever-evolving landscape by providing compliance solutions tailored to your specific needs.",
        
        navigation: {
          title: "Navigate with Confidence in the Regulatory World",
          features: {
            audit: {
              title: "Compliance Audit",
              description: "We conduct thorough audits to identify gaps between your current practices and regulatory requirements."
            },
            conformity: {
              title: "Compliance Implementation",
              description: "Our experts assist you in implementing policies and procedures that comply with national and international standards."
            },
            monitoring: {
              title: "Regulatory Monitoring",
              description: "We keep you informed of legislative changes that may impact your business."
            },
            risk: {
              title: "Risk Management",
              description: "We assess non-compliance risks and develop action plans to mitigate them."
            }
          }
        },
  
        tools: {
          title: "Modern Tools for Effective Compliance",
          cards: {
            platform: {
              title: "Management Platforms",
              description: "Centralize all your regulatory obligations in a single tool for simplified monitoring."
            },
            automation: {
              title: "Process Automation",
              description: "Reduce human error and save time by automating repetitive tasks."
            },
            analytics: {
              title: "Advanced Analytics",
              description: "Leverage your data to anticipate risks and make informed decisions."
            },
            reporting: {
              title: "Custom Reporting",
              description: "Receive detailed reports tailored to your needs for effective compliance management."
            }
          }
        },
  
        whyUs: {
          title: "Why Choose Blueagate for Your Compliance?",
          cards: {
            expertise: "Multidisciplinary Expertise: A team of lawyers, engineers, and compliance specialists.",
            technology: "Innovative Technologies: Modern tools for efficient and proactive compliance management.",
            approach: "Personalized Approach: Tailored solutions adapted to your industry sector.",
            commitment: "Commitment and Discretion: A trust-based relationship founded on confidentiality."
          }
        },
  
        cta: {
          title: "Stay Ahead in Compliance Matters",
          description: "Don't let regulatory complexity hinder your success. With {{company}}, transform compliance into a strategic asset that strengthens your reputation and performance.",
          button: "Contact Us Today"
        }
      }
    }
  };