// src/i18n/translations/services.js

export const servicesTranslations = {
    fr: {
      services: {
        title: "Nos Services",
        subtitle: "Des solutions de cybersécurité complètes pour protéger votre entreprise dans un monde numérique en constante évolution.",
        learnMore: "En savoir plus",
        viewAllButton: "Tous nos services",
        cards: {
          threatReduction: {
            title: "Réduction de la menace",
            description: "Anticipez et neutralisez les menaces avant qu'elles ne deviennent critiques pour votre entreprise."
          },
          compliance: {
            title: "Compliance",
            description: "Assurez-vous que votre entreprise respecte les normes et réglementations en vigueur."
          },
          incidentManagement: {
            title: "Gestion des incidents",
            description: "Réagissez rapidement et efficacement aux incidents de sécurité pour minimiser leur impact."
          },
          audit: {
            title: "Audit",
            description: "Évaluez vos systèmes pour identifier et corriger les vulnérabilités de sécurité."
          }
        }
      }
    },
    en: {
      services: {
        title: "Our Services",
        subtitle: "Comprehensive cybersecurity solutions to protect your business in an ever-evolving digital world.",
        learnMore: "Learn more",
        viewAllButton: "View all services",
        cards: {
          threatReduction: {
            title: "Threat Reduction",
            description: "Anticipate and neutralize threats before they become critical to your business."
          },
          compliance: {
            title: "Compliance",
            description: "Ensure your business complies with current standards and regulations."
          },
          incidentManagement: {
            title: "Incident Management",
            description: "React quickly and effectively to security incidents to minimize their impact."
          },
          audit: {
            title: "Audit",
            description: "Evaluate your systems to identify and correct security vulnerabilities."
          }
        }
      }
    }
  };