import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../hooks/useApi';

const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const { loading, error, callApi } = useApi();

  const fetchArticle = useCallback(async () => {
    const data = await callApi(`/api/articles/${id}`);
    if (data) {
      setArticle(data);
    }
  }, [callApi, id]);

  useEffect(() => {
    fetchArticle();
  }, [fetchArticle]);

  if (loading) return <p>Chargement de l'article...</p>;
  if (error) return <p>Erreur: {error}</p>;
  if (!article) return <p>Article non trouvé</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">{article.title}</h1>
      <p className="text-gray-600 mb-4">Publié le {new Date(article.date).toLocaleDateString()}</p>
      <div className="prose lg:prose-xl">{article.content}</div>
    </div>
  );
};

export default ArticleDetail;