// src/i18n/translations/hero.js

export const heroTranslations = {
  fr: {
    hero: {
      title: {
        start: "Sécurisez votre",
        highlight: "avenir numérique"
      },
      description: "Des solutions de cybersécurité innovantes pour protéger votre entreprise dans un monde connecté",
      imageAlt: "Fond cybersécurité",
      ctaButton: "Demandez une consultation"
    }
  },
  en: {
    hero: {
      title: {
        start: "Secure your",
        highlight: "digital future"
      },
      description: "Innovative cybersecurity solutions to protect your business in a connected world",
      imageAlt: "Cybersecurity background",
      ctaButton: "Request a consultation"
    }
  }
};